import { graphql } from "gatsby"
import * as React from "react"
import Header from "../components/common/Header"
import Features from "../components/Features"
import Hero from "../components/Hero"
import Layout from "../components/Layout"
import PrivacyPolicyContent from "../components/PrivacyPolicyContent"
import SEO from "../components/seo"

export const privaryPolicyPageQuery = graphql`
  query {
    allContentfulPrivacyPolicyPage {
      edges {
        node {
          heroSectionTitle
          choicesSectionTitle
          privacyPolicySectionTitle
          privacyPolicies {
            raw
          }
          heroSectionImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          choicesSectionContentBoxes {
            title
            image {
              fluid {
                ...GatsbyContentfulFluid
              }
            }
            description {
              description
            }
          }

          heroSectionDescription {
            heroSectionDescription
          }
          choicesSectionDescription {
            choicesSectionDescription
          }
        }
      }
    }
  }
`

const PrivacyPolicy = ({ data }) => {
  const {
    allContentfulPrivacyPolicyPage: {
      edges: [
        {
          node: {
            heroSectionTitle,
            heroSectionDescription,
            heroSectionImage,
            privacyPolicySectionTitle,
            privacyPolicies,
            choicesSectionTitle,
            choicesSectionDescription,
            choicesSectionContentBoxes,
          },
        },
      ],
    },
  } = data

  return (
    <Layout>
      <SEO title="Home" />
      <div className="top-section privacy-policy">
        <Header />
        <Hero
          isPrivacyPolicy
          title={heroSectionTitle}
          description={heroSectionDescription?.heroSectionDescription}
          image={heroSectionImage}
        />
      </div>
      <Features
        title={choicesSectionTitle}
        description={choicesSectionDescription?.choicesSectionDescription}
        contentBoxes={choicesSectionContentBoxes}
      />
      <PrivacyPolicyContent
        title={privacyPolicySectionTitle}
        policies={privacyPolicies}
      />
    </Layout>
  )
}

export default PrivacyPolicy
